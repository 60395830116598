<div class="p-2">
    <app-general-modal-header [title]="'Connect Wallet'" (modalClose)="close()"></app-general-modal-header>

    <div class="flex flex-col justify-start items-stretch gap-y-2.5 font-bold w-full">
        <div
            *ngFor="let wallet of wallets"
            class="item w-full border border-line h-[64px] py-1.5 px-4 rounded-2xl flex justify-start items-center gap-x-2.5 cursor-pointer text-base transition-shadow hover:shadow-md"
            (click)="connect(wallet.name)"
        >
            <img class="w-10 h-10 object-contain" [src]="wallet.img" [alt]="wallet.displayName" />
            <div class="font-bold" style="line-height: 1.2">{{wallet.displayName}}</div>
            <span *ngIf="connectingWallet === wallet.name" nz-icon nzType="loading" nzTheme="outline"></span>
        </div>
    </div>
</div>
