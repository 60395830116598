<div *ngIf="minting" class="!fixed z-50 left-0 top-0 w-screen h-screen bg-white bg-opacity-30" appLoading [loading]="true"></div>

<div class="text-center cursor-pointer relative min-w-[110px]" (click)="goContract()">
    <div class="flex justify-center items-center absolute top-0 w-full z-10">
        <div
            *ngIf="!badge.is_earned && badge.points > 0"
            class="w-fit flex justify-end items-center gap-x-1 bg-[#331200] rounded-2xl py-1 px-2 translate-x-[40%]"
        >
            <img src="/assets/imgs/icon/sun-v2.png" class="w-4" alt="" />
            <div class="gradient-color text-xs font-normal leading-[1] w-fit">{{badge.points}}</div>
        </div>

        <div
            *ngIf="!badge.is_earned && badge.section === 'DAY Mining'"
            class="w-fit flex justify-end items-center gap-x-1 bg-[#331200] rounded-2xl py-1 px-2 translate-x-[40%]"
        >
            <img src="/assets/imgs/hemera-round.png" class="w-4" alt="" />
            <div *ngIf="badge.id !== 'ranking'" class="gradient-color text-xs font-normal leading-[1] w-fit">
                <ng-container *ngIf="badge.id === 'newbie'">60</ng-container>
                <ng-container *ngIf="badge.id === 'og'">120</ng-container>
            </div>
            <div *ngIf="badge.id === 'ranking'" class="text-xs font-normal leading-[1] w-fit">
                <span class="gradient-color">720</span>
                <span class="gradient-color text-2xs"> Max</span>
            </div>
        </div>
    </div>

    <div [nz-tooltip]="badge.description">
        <img
            [src]="badge.icon_enabled || '/assets/imgs/badges/follower-1000.png'"
            [alt]="badge.name"
            class="w-[64px] h-[64px] mx-auto"
            [class.not-owned]="!badge.is_earned"
        />
    </div>

    <div class="mt-2 text-xs font-normal">{{badge.name}}</div>

    <div
        *ngIf="badge.is_earned || badge.type === 'onchain'"
        class="flex justify-center items-center text-xs p-1.5 gap-x-1"
        [ngClass]="{
            'border border-white border-opacity-20 hover:border-opacity-50 rounded-md px-2 py-1 leading-[1] w-fit mx-auto mt-2':badge.type === 'onchain'
        }"
    >
        <span *ngIf="badge.type !== 'onchain'" class="text-w3w">{{badge.rarity | percent}} people</span>
        <ng-container *ngIf="badge.type === 'onchain'">
            <span>{{badge.user_count}} Holders </span>
            <span *ngIf="badge.is_earned" nz-icon nzType="antd:right-arrow-circle" class="text-base"></span>
        </ng-container>
    </div>
</div>
