<div>
    <div *ngIf="!type" class="text-xs text-sub-text">
        The SocialScan points system is designed to reward our loyal platform users. You can earn by completing the tasks. The points will be used to claim
        platform benefits in the future.
    </div>

    <ng-container *ngFor="let item of pointsHistory; let first = first">
        <div *ngIf="!type || !first" class="bg-line h-[1px] my-5 bg-opacity-30"></div>

        <div class="flex justify-between items-center w-full">
            <div>
                <div class="text-base mb-1">
                    <span>{{item.reference_name || PointTaskNameMap[item.task_type]}}</span>
                </div>
                <div class="text-xs">{{item.updated_at| date:'HH:mm MMM dd, yyyy'}}</div>
            </div>
            <div class="text-right">
                <div class="text-base font-bold text-w3w">+{{item.points}}</div>
                <div *ngIf="!item.is_finalized" class="text-sub-text text-xs mt-1">Pending</div>
            </div>
        </div>
    </ng-container>

    <div *ngIf="pointsHistory?.length === 0" class="flex justify-center items-center py-10 flex-col">
        <img src="/assets/imgs/icon/empty-label-address.png" alt="" class="w-[120px]" />
        <div class="text-center my-5 text-base font-normal">No History</div>
    </div>

    <nz-skeleton *ngIf="!isLast" [nzActive]="true" appScrollObserver (scrolledIntoView)="getPointsHistory()"></nz-skeleton>
</div>
