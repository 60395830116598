<footer class="bg-[#0E0E0E] relative z-10">
    <div class="gradient-line w-full h-[1px]"></div>
    <div class="container py-6 mx-auto">
        <div class="flex-between-center gap-x-5">
            <a [routerLink]="walletStatusService.isWalletConnected ? ['/','my-profile','dashboard'] : ['/']" class="flex justify-start items-center">
                <img alt="SocialScan" title="SocialScan" src="/assets/imgs/v3/socialscan-v3.png" class="h-9" />
            </a>
            <div class="flex justify-end items-center gap-x-8">
                <a href="https://status.socialscan.io" target="_blank" class="text-white hover:text-white text-3xl">
                    <span nz-icon nzType="global" nzTheme="outline"></span>
                </a>
                <a href="https://twitter.com/socialscan_io" target="_blank" class="text-white hover:text-white text-3xl">
                    <span nz-icon nzType="twitter-circle" nzTheme="fill"></span>
                </a>
                <a href="https://discord.gg/ZqYx5dXsAh" target="_blank" class="text-white hover:text-white text-3xl">
                    <span nz-icon nzType="antd:discord" nzTheme="fill"></span>
                </a>
            </div>
        </div>

        <div class="w-full flex-between-center gap-x-5 gap-y-2 text-xs mt-8 flex-wrap">
            <div class="flex-start-center gap-x-8">
                <div>w3w.ai@{{year}}</div>
                <a class="text-white" [routerLink]="['/','policy','terms-of-service']">Terms of Service</a>
                <a class="text-white" [routerLink]="['/','policy','privacy-policy']">Privacy Policy</a>
            </div>

            <div class="flex-start-center w-auto gap-x-8">
                <a class="text-white" [routerLink]="['/','about-us']">About Us</a>
                <a class="text-white" [routerLink]="['/','blogs']">Blogs</a>
                <a class="text-white" href="/assets/others/SocialScan.zip" download> Brand Kit </a>
            </div>
        </div>
    </div>
</footer>
