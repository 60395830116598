import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ConnectWalletModalComponent } from '@shared/components/connect-wallet-modal/connect-wallet-modal.component';
import { BadgeApiInfo } from '@shared/types/badge.type';
import { ChainEnum, getChainImg, getChainInfo, getChainSymbolIconByChainName } from '@shared/types/wallet-chain.type';
import { loadScript } from '@shared/utils/browser';
import { formatDate } from '@shared/utils/datetime.util';
import { tokenImage } from '@shared/utils/file';
import { createIdenticon } from '@shared/utils/identicon';
import { formatNumberToKMB } from '@shared/utils/number.util';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageDataOptions, NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { IResponseError } from './request.service';

import { BadgeInfoComponent } from '@shared/components/badge-info/badge-info.component';

export enum MessageEnum {
    ERROR = 'Oops! Something went wrong. Please try again.',
    LOADING = 'Loading',
}

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    get isMobile() {
        if (isPlatformBrowser(this.platformId)) {
            return window?.innerWidth < 768;
        }
        return false;
    }

    get isTablet() {
        if (isPlatformBrowser(this.platformId)) {
            return window?.innerWidth >= 768 && window?.innerWidth < 1024;
        }

        return false;
    }

    colors = ['#E07BC4', '#7BE0CE', '#E0C47B', '#957BE0', '#E0877B'];

    pageInfoJson: any;

    modalMobileStyle = {
        verticalAlign: 'bottom',
        maxWidth: '100vw',
        padding: 0,
        margin: 0,
    };

    addressIconMap = new Map<string, string>();

    constructor(
        private message: NzMessageService,
        private modalService: NzModalService,
        private drawerService: NzDrawerService,
        private $title: Title,
        private $meta: Meta,
        @Inject(PLATFORM_ID) private platformId: any
    ) {}

    success(content = '', options?: NzMessageDataOptions) {
        this.message.success(content, options);
    }

    error(content?: string, options?: NzMessageDataOptions) {
        const handledOptions = {
            ...{ nzDuration: 10000 },
            ...(options || {}),
        };
        this.message.error(content ?? MessageEnum.ERROR, handledOptions);
    }

    warning(content = '', options?: NzMessageDataOptions) {
        this.message.warning(content, options);
    }

    info(content = '', options?: NzMessageDataOptions) {
        this.message.info(content, options);
    }

    loading(content = '', options?: NzMessageDataOptions) {
        this.message.loading(content ?? MessageEnum.LOADING, options);
    }

    errorMessageByResponse(error: IResponseError) {
        if (error.status === 400) {
            this.error(error.body.message, { nzDuration: 5000 });
        } else {
            this.error(error.message, { nzDuration: 5000 });
        }
    }

    showLogInModal() {
        if (this.isMobile) {
            this.drawerService.create({
                nzTitle: null,
                nzFooter: null,
                nzContent: ConnectWalletModalComponent,
                nzClosable: false,
                nzPlacement: 'bottom',
                nzHeight: 'auto',
            });
        } else {
            this.modalService.create({
                nzTitle: null,
                nzFooter: null,
                nzContent: ConnectWalletModalComponent,
                nzClosable: false,
                nzWidth: '340px',
                nzCentered: true,
            });
        }
    }

    showUpdateEmailModal(type: 'first' | 'follow' = 'follow') {
        // TODO: Hide for campaign
        // if (this.isMobile) {
        //     this.drawerService.create({
        //         nzTitle: 'Add Email',
        //         nzFooter: null,
        //         nzContent: EmailPopupComponent,
        //         nzPlacement: 'bottom',
        //         nzHeight: 'auto',
        //         nzContentParams: { type },
        //     });
        // } else {
        //     this.modalService.create({
        //         nzTitle: 'Add Email',
        //         nzFooter: null,
        //         nzCentered: true,
        //         nzContent: EmailPopupComponent,
        //         nzData: { type },
        //     });
        // }
    }

    setAddressIconMap(addressList: string[]) {
        if (!addressList) return;
        addressList
            ?.filter(item => !!item)
            .forEach(address => {
                if (!this.addressIconMap.has(address.toLowerCase())) {
                    try {
                        this.addressIconMap.set(address.toLowerCase(), createIdenticon(address));
                    } catch (err) {
                        console.error(err);
                    }
                }
            });
    }

    getChainSymbolIconByChainName(chainName: ChainEnum, blueEth = false) {
        getChainSymbolIconByChainName(chainName, blueEth);
    }

    getChainImg(chain: ChainEnum) {
        return getChainImg(chain);
    }

    getChainInfoByName(chain: ChainEnum) {
        return getChainInfo(chain);
    }

    getAddressIcon(address: string) {
        if (!this.addressIconMap.has(address?.toLowerCase())) {
            this.setAddressIconMap([address]);
        }
        return this.addressIconMap.get(address?.toLowerCase()) || '/assets/imgs/fallback.png';
    }

    formatNumberToKMB(num: number, digitsCount = 2, skipK = false) {
        return formatNumberToKMB(num, digitsCount, skipK);
    }

    loadScript(src: string) {
        return loadScript(src);
    }

    formatDate(startDate: string, endDate: string, timezone = 'Asia/Singapore') {
        return formatDate(startDate, endDate, timezone);
    }

    tokenImage(
        chain: ChainEnum,
        contractAddr: string,
        tokenId: any,
        imgOptions?: {
            width?: number;
            format?: string;
        }
    ) {
        return tokenImage(chain, contractAddr, tokenId, imgOptions);
    }

    go(url: string) {
        window.open(url, '_blank');
    }

    seoSetup(data: { title: string; description?: string; keywords?: string; ogImage?: string }) {
        this.$title.setTitle(data.title);
        // Set og:title
        this.$meta.updateTag({ property: 'og:title', content: data.title });

        if (data.description) {
            this.$meta.updateTag({ name: 'description', content: data.description });
            // Set og:description
            this.$meta.updateTag({ property: 'og:description', content: data.description });
        }

        if (data.keywords) {
            this.$meta.updateTag({ name: 'keywords', content: data.keywords });
        }

        if (data.ogImage) {
            this.$meta.updateTag({ property: 'og:image', content: data.ogImage });
        }
    }

    getPageInfoByPath(path: string) {
        if (this.pageInfoJson) {
            return Promise.resolve(this.pageInfoJson[path]);
        }

        return fetch('/assets/json/seo.json')
            .then(res => res.json())
            .then(data => {
                this.pageInfoJson = data;
                return data[path];
            });
    }

    setPageInfo(resetIfNoData = true) {
        if (isPlatformBrowser(this.platformId)) {
            const path = new URL(window?.location.href)?.pathname;
            this.getPageInfoByPath(path).then(data => {
                if (data) {
                    this.seoSetup(data);
                } else {
                    if (resetIfNoData) {
                        // Set to Default Page Info
                        this.seoSetup({
                            title: 'SocialScan - Community-Owned AI Specialized in Everything On-Chain',
                            description:
                                'SocialScan transforms web3 discovery with its AI-powered, community-native platform, providing a comprehensive, real-time view of assets, communities, and members across the web3 ecosystem.',
                            keywords:
                                'SocialScan, Hemera, Hemera Protocol, Web3 Community, Web3 Social, Web3 Discovery, Social Graph, Web3 Networking, Web3 Trends, Whale Alert, Whale Fomo, Whale Transactions, Active Whales, Inscription Token, Inscription Trend, Inscription Index, Top Inscriptions, Bitcoin Ordinals, Bitcoin Inscription, EVM Inscription, Latest Inscriptions, Alpha Signals, Mint Rank, On-Chain Data, Data Interoperability, Public Goods Infrastructure, SocialScan Explorer, Blockchain Explorer, Explorer, Blockchain, Crypto, Currency, EVM Chains, Polygon, Polygon zkEVM, Polygonscan, Ethereum, Etherscan, Binance, BNB, BscScan, Solana, Solscan, Mantle, Mantle Network, Linea, ZetaChain, Arthera, Immutable, Chainlink, Manta Network, Debank, Blockscout, Crypto Transaction, On-Chain Activity, Token Transfer, Cross-Chain Transactions, Smart Contract, NFT Holdings, Badge Incentive, Point Incentive, Token Incentive, Sending Labs, Web3 Message, Web3 IM',
                        });
                    }
                }
            });
        }
    }

    getAddrName(data: { [key: string]: any; display_name?: string; wallet_address?: string; ens_name?: string }) {
        return data?.display_name || data?.ens_name || data?.wallet_address;
    }

    orderNameConverter(order: 'ascend' | 'descend') {
        if (!order) return null;

        return order === 'ascend' ? 'asc' : 'desc';
    }

    showGotNewBadgesModal(badges: BadgeApiInfo[], additionalPoints = 0, additionalInvitationCodes = 0) {
        if (this.isMobile) {
            this.drawerService.create({
                nzTitle: null,
                nzFooter: null,
                nzContent: BadgeInfoComponent,
                nzPlacement: 'bottom',
                nzHeight: 'auto',
                nzContentParams: { badges, additionalPoints, additionalInvitationCodes },
            });
        } else {
            const comp = this.modalService
                .create({
                    nzTitle: null,
                    nzFooter: null,
                    nzContent: BadgeInfoComponent,
                    nzWidth: '500px',
                    nzCentered: true,
                })
                .getContentComponent();

            comp.badges = badges;
            comp.additionalPoints = additionalPoints;
            comp.additionalInvitationCodes = additionalInvitationCodes;
        }
    }
}
