export const numSubUnicodeMap = new Map([
    ['0', '₀'],
    ['1', '₁'],
    ['2', '₂'],
    ['3', '₃'],
    ['4', '₄'],
    ['5', '₅'],
    ['6', '₆'],
    ['7', '₇'],
    ['8', '₈'],
    ['9', '₉'],
]);

export function formatNumberToKMB(num: number, digitsCount = 2, skipK = false): string {
    if (!num) return String(num);

    let divisor, suffix;

    if (num >= 1e9) {
        divisor = 1e9;
        suffix = 'B';
    } else if (num >= 1e6) {
        divisor = 1e6;
        suffix = 'M';
    } else if (num >= 1e3 && !skipK) {
        divisor = 1e3;
        suffix = 'K';
    } else {
        return formatWithCommas(num, digitsCount);
    }

    const formatted = (num / divisor).toFixed(digitsCount);
    return formatWithCommas(parseFloat(formatted), digitsCount) + suffix;
}

/**
 * Formats the number with commas and specifies the maximum number of digits after the decimal point.
 * Unnecessary trailing zeros after the decimal point are removed.
 * Commas are only added to the integer part of the number.
 * @param num The number to format
 * @param maxDigits The maximum number of decimal digits
 */
function formatWithCommas(num: number, maxDigits: number): string {
    let result = num.toFixed(maxDigits);
    // Remove unnecessary trailing zeros and decimal point if not needed
    result = parseFloat(result).toString();
    const parts = result.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

/**
 * 格式化数字小数部分
 * 1. 格式化小数部分，也就是数字小于 0.000001 的时候
 * 2. 比如 0.00000001 => 0.0₇1
 * 3. 比如 0.000000045323434534 => 0.0₇4532
 *  */

export function formatDecimalPart(num: number, nonZeroLen = 4): string {
    if (num >= 0.000001 || num <= -0.000001 || num === 0) return formatNumberToKMB(num, 6, true);

    const str = num.toFixed(20); // Use a fixed number of decimal places to avoid scientific notation
    let index = str.indexOf('.') + 1; // Locate the decimal point

    // Count leading zeros directly after the decimal point
    while (str[index] === '0') {
        index++;
    }

    const leadingZeros = index - (str.indexOf('.') + 1); // Calculate number of leading zeros
    let significantDigits = str.substring(index, index + nonZeroLen); // Extract up to 5 digits initially

    // Remove unnecessary trailing zeros
    significantDigits = significantDigits.replace(/0+$/, '');

    // Map leading zeros count to subscript characters
    const subscriptZeros = Array.from(leadingZeros.toString())
        .map(digit => numSubUnicodeMap.get(digit))
        .join('');

    return `0.0${subscriptZeros}${significantDigits}`; // Construct and return the formatted string
}
