import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { VisualMapComponentOption } from 'echarts';

import { BarChart, BarSeriesOption, LineChart, LineSeriesOption, PieChart, PieSeriesOption } from 'echarts/charts';
import {
    GridComponent,
    GridComponentOption,
    LegendComponent,
    TitleComponent,
    TitleComponentOption,
    ToolboxComponent,
    TooltipComponent,
    VisualMapComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers';
echarts.use([
    GridComponent,
    LineChart,
    PieChart,
    BarChart,
    CanvasRenderer,
    TooltipComponent,
    TitleComponent,
    LegendComponent,
    ToolboxComponent,
    VisualMapComponent,
    SVGRenderer,
]);

export type EchartsOption = echarts.ComposeOption<
    GridComponentOption | LineSeriesOption | PieSeriesOption | BarSeriesOption | TitleComponentOption | VisualMapComponentOption
>;
export { echarts };

@Directive({
    selector: '[appEcharts]',
})
export class EchartsDirective implements AfterViewInit, OnChanges {
    @Input() initialOption: EchartsOption;
    @Input() optionToBeUpdate: EchartsOption;
    @Input() theme: 'light' | 'dark' = 'dark';

    private charts: echarts.EChartsType;
    private chartInitialized = false;

    constructor(private el: ElementRef) {}

    @HostListener('window:resize')
    resizeChart() {
        this.charts?.resize();
    }

    ngOnChanges(change: SimpleChanges) {
        if (!change['optionToBeUpdate']?.currentValue) return;

        if (this.chartInitialized) {
            this.charts.setOption(this.optionToBeUpdate);
        } else {
            const func = () => {
                if (this.charts) {
                    this.charts.setOption(this.optionToBeUpdate);
                } else {
                    setTimeout(() => {
                        func();
                    }, 10);
                }
            };

            func();
        }
    }

    showLoading() {
        this.charts.showLoading();
    }

    hideLoading() {
        this.charts.hideLoading();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.charts = echarts.init(this.el.nativeElement, this.theme, { renderer: 'svg' });
            this.charts.setOption(this.initialOption);
            this.chartInitialized = true;
        }, 10);
    }
}
