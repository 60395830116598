<div class="search-result">
    <ng-container *ngIf="data?.length; else noResultTemp">
        <div class="tabs" (click)="$event.stopPropagation();">
            <div [class.active]="!selectedTab" class="tab-item" (click)="selectedTab = null">
                <span [ngClass]="{'text-w3w': !selectedTab}">All</span>
            </div>
            <div *ngIf="addressList?.length" class="tab-item" [class.active]="selectedTab === 'address'" (click)="selectedTab = 'address'">
                <span [ngClass]="{'text-w3w': selectedTab === 'address'}">Address</span>
            </div>
            <div
                *ngIf="txnList?.length"
                class="tab-item"
                [class.active]="selectedTab === 'transaction'"
                (click)="selectedTab = 'transaction'"
            >
                <span [ngClass]="{'text-w3w': selectedTab === 'transaction'}">Txn Hash</span>
            </div>
            <div
                *ngIf="communityList?.length"
                class="tab-item"
                [class.active]="selectedTab === 'community'"
                (click)="selectedTab = 'community'"
            >
                <span [ngClass]="{'text-w3w': selectedTab === 'community'}">Community</span>
            </div>
            <div
                *ngIf="contractList?.length"
                class="tab-item"
                [class.active]="selectedTab === 'contract'"
                (click)="selectedTab = 'contract'"
            >
                <span [ngClass]="{'text-w3w': selectedTab === 'contract'}">Contract</span>
            </div>

            <div *ngIf="companyList?.length" class="tab-item" [class.active]="selectedTab === 'company'" (click)="selectedTab = 'company'">
                <span [ngClass]="{'text-w3w': selectedTab === 'company'}">Company</span>
            </div>

            <div *ngIf="userList?.length" class="tab-item" [class.active]="selectedTab === 'user'" (click)="selectedTab = 'user'">
                <span [ngClass]="{'text-w3w': selectedTab === 'company'}">User</span>
            </div>

            <div
                *ngIf="inscriptionList?.length"
                class="tab-item"
                [class.active]="selectedTab === 'inscription'"
                (click)="selectedTab = 'inscription'"
            >
                <span [ngClass]="{'text-w3w': selectedTab === 'inscription'}">Inscription</span>
            </div>
        </div>

        <div class="mt-2 overflow-y-auto" [ngStyle]="{'max-height': mode === 'desktop' ? '300px' : 'calc(100vh - 150px)'}">
            <!-- Address -->
            <div *ngIf="(!selectedTab || selectedTab === 'address') && addressList?.length">
                <div *ngIf="!selectedTab" class="font-bold my-1.5">Address</div>
                <div
                    *ngFor="let item of addressList"
                    class="flex items-center px-1.5 py-2 cursor-pointer hover:bg-white hover:bg-opacity-6 rounded-md"
                    (click)="go('/address/'+item.wallet_address)"
                >
                    <img [src]="item.profile_image || commonService.getAddressIcon(item.wallet_address)" class="w-8 h-8 rounded-full" />
                    <div class="pl-3" style="width: calc(100% - 32px)">
                        <div *ngIf="item.ens_name"><span class="colorful-text font-medium">{{ item.ens_name }}</span></div>
                        <div class="text-xs truncate">{{ item.wallet_address }}</div>
                    </div>
                </div>
            </div>

            <!-- Txn Hash -->
            <div *ngIf="(!selectedTab || selectedTab === 'transaction') && txnList?.length">
                <div *ngIf="!selectedTab" class="font-bold my-1.5">Txn Hash</div>
                <div
                    *ngFor="let item of txnList"
                    class="flex items-center px-1.5 py-2 cursor-pointer hover:bg-white hover:bg-opacity-6 rounded-md"
                    (click)="go('/transaction/'+item.chain+'/'+item.transaction_hash)"
                >
                    <img src="/assets/imgs/txn-hash-icon.png" class="w-5 h-5" style="filter: contrast(0.1)" />
                    <img [src]="commonService.getChainImg(item.chain)" class="w-5 h-5 ml-1.5" />
                    <span class="ml-1.5 truncate" style="max-width: calc(100% - 55px)">{{ item.transaction_hash }}</span>
                </div>
            </div>

            <!-- Community -->
            <div *ngIf="(!selectedTab || selectedTab === 'community') && communityList?.length">
                <div *ngIf="!selectedTab" class="font-bold my-1.5">Community</div>
                <div
                    *ngFor="let item of communityList"
                    class="flex items-center px-1.5 py-2 cursor-pointer hover:bg-slate-300 rounded-md"
                    (click)="go('/community/'+item.community_key)"
                >
                    <img [src]="item.community_logo" class="w-10 h-10 rounded-full" />
                    <div class="pl-3" style="width: calc(100% - 32px)">
                        <div>{{ item.name }}</div>
                        <div class="text-xs">{{ item.contract_list?.length }} Contracts</div>
                    </div>
                </div>
            </div>

            <!-- Contract -->
            <div *ngIf="(!selectedTab || selectedTab === 'contract') && contractList?.length">
                <div *ngIf="!selectedTab" class="font-bold my-1.5">Contract</div>
                <div
                    *ngFor="let item of contractList"
                    class="flex items-center px-1.5 py-2 cursor-pointer hover:bg-slate-300 rounded-md"
                    (click)="go('/community/contract/'+item.chain+'/'+item.contract_address)"
                >
                    <img [src]="item.contract_image_url || '/assets/imgs/fallback.png'" class="w-10 h-10 rounded-full" />
                    <div class="pl-3" style="width: calc(100% - 40px)">
                        <div>
                            <img [src]="getChainSymbolIconByChainName(item.chain)" class="w-5 h-5 inline-block align-middle" />
                            <span class="inline-block align-middle ml-1.5">{{ item.contract_name }}</span>
                        </div>
                        <div class="text-xs truncate">{{ item.contract_address }}</div>
                    </div>
                </div>
            </div>

            <!-- Company -->
            <div *ngIf="(!selectedTab || selectedTab === 'company') && companyList?.length">
                <div *ngIf="!selectedTab" class="font-bold my-1.5">Company</div>
                <div
                    *ngFor="let item of companyList"
                    class="flex items-center px-1.5 py-2 cursor-pointer hover:bg-slate-300 rounded-md"
                    (click)="go('/company/'+item.company_id)"
                >
                    <img [src]="item.company_logo" class="w-10 h-10 rounded-full" />
                    <div class="pl-3" style="width: calc(100% - 32px)">
                        <div>{{ item.company_name }}</div>
                    </div>
                </div>
            </div>

            <!-- User -->
            <div *ngIf="(!selectedTab || selectedTab === 'user') && userList?.length">
                <div *ngIf="!selectedTab" class="font-bold my-1.5">User</div>
                <div
                    *ngFor="let item of userList"
                    class="flex items-center px-1.5 py-2 cursor-pointer hover:bg-slate-300 rounded-md"
                    (click)="go('/address/'+item.user_id)"
                >
                    <img
                        nz-image
                        [nzDisablePreview]="true"
                        [nzSrc]="item.user_profile_image "
                        [nzFallback]="'/assets/imgs/fallback.png'"
                        class="w-10 h-10 rounded-full"
                    />
                    <div class="pl-3" style="width: calc(100% - 32px)">
                        <div>{{ item.user_display_name }}</div>
                    </div>
                </div>
            </div>

            <!-- Inscription -->
            <div *ngIf="(!selectedTab || selectedTab === 'inscription') && inscriptionList?.length">
                <div *ngIf="!selectedTab" class="font-bold my-1.5">Inscription</div>
                <div
                    *ngFor="let item of inscriptionList"
                    class="flex justify-start items-center px-1.5 py-2 cursor-pointer hover:bg-slate-300 rounded-md"
                    (click)="go('/inscription/'+item.chain + '/' + item.protocol + '/' + item.ticker)"
                >
                    <nz-avatar
                        [nzText]="item.name[0] | uppercase"
                        [nzShape]="'circle'"
                        [nzSrc]="null"
                        class="!w-10 !h-10 !text-lg !leading-[40px]"
                        style="background-color: #e6f0ff; color: #2f88ff"
                    ></nz-avatar>

                    <div class="pl-3 flex justify-start items-center gap-x-2" style="width: calc(100% - 32px)">
                        <span>{{ item.name }}</span>
                        <img [src]="getChainIcon(item.chain)" [nz-tooltip]="getChainName(item.chain)" alt="" class="w-5 h-5 rounded-full" />
                        <span class="text-xs bg-badge px-2 rounded-xl text-opacity-50 text-black">{{ item.protocol | uppercase }}</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #noResultTemp> No relevant data found. Please double check your input</ng-template>
