<!-- Desktop -->
<header *ngIf="!commonService.isMobile" class="bg-opacity-10 relative">
    <div class="general-bg"></div>
    <div class="flex-between-center container py-5">
        <img src="/assets/imgs/v3/socialscan-v3.png" class="h-10 cursor-pointer" alt="SocialScan" [routerLink]="['/']" />

        <div class="flex-end-center gap-x-5 flex-1">
            <nav *ngIf="!desktopSearching" class="flex-1 flex justify-start items-center navigation-links pl-10">
                <div *ngIf="walletStatusService.isWalletConnected && walletStatusService.registrationCompleted" class="flex-start-center gap-x-10">
                    <button
                        [nzType]="selectedTab === 'Home' ? 'primary' : 'text'"
                        [ngClass]="selectedTab === 'Home' ? '' : '!transition-none'"
                        nz-button
                        nzType="primary"
                        [routerLink]="['/my-profile']"
                    >
                        Home
                    </button>

                    <button
                        class="cursor-pointer p-1 select-none italic font-normal h-10"
                        [ngClass]="selectedTab === 'Agents' ? '' : '!transition-none'"
                        nz-button
                        [nzType]="selectedTab === 'Agents' ? 'primary' : 'text'"
                        [routerLink]="['/hemera','agents']"
                        [@tada]="animateState"
                    >
                        <span [ngClass]="selectedTab === 'Agents' ? '' : 'colorful-text'">AI Agent Hub</span>
                    </button>

                    <button
                        class="cursor-pointer p-1 select-none italic font-normal h-10"
                        [ngClass]="selectedTab === 'DayMining' ? '' : '!transition-none'"
                        nz-button
                        [nzType]="selectedTab === 'DayMining' ? 'primary' : 'text'"
                        [routerLink]="['/hemera','agent','mining-days']"
                    >
                        <img *ngIf="selectedTab !== 'DayMining'" src="/assets/imgs/icon/colorful-hoe.png" class="w-5 mr-2" />
                        <img *ngIf="selectedTab === 'DayMining'" src="/assets/imgs/icon/hoe-white.png" class="w-5 mr-2" />

                        <span [ngClass]="selectedTab === 'DayMining' ? '' : 'colorful-text'"> $DAY Mining </span>
                    </button>
                </div>
            </nav>

            <div class="flex justify-start items-center gap-x-5">
                <div *ngIf="walletStatusService.isWalletConnected && walletStatusService.registrationCompleted" (click)="$event.stopPropagation()">
                    <app-search-input *ngIf="desktopSearching" mode="desktop" class="block w-[340px]"></app-search-input>

                    <div *ngIf="!desktopSearching" class="flex-start-center gap-x-5">
                        <div
                            class="w-10 text-xl h-10 rounded-full flex justify-center items-center cursor-pointer bg-black hover:bg-opacity-50"
                            (click)="desktopSearching = true"
                        >
                            <span nz-icon nzType="antd:search"></span>
                        </div>

                        <div
                            class="w-10 text-xl h-10 rounded-full flex justify-center items-center cursor-pointer bg-black hover:bg-opacity-50 relative"
                            nz-dropdown
                            [nzDropdownMenu]="notificationMenu"
                            [nzTrigger]="'click'"
                        >
                            <span nz-icon nzType="antd:bell"></span>

                            <div *ngIf="hasUnreadNotifications" class="absolute w-2 h-2 rounded-full bg-[#FA4E3E] right-2.5 top-2.5"></div>
                        </div>
                    </div>
                </div>

                <button *ngIf="!isWalletConnected" nz-button nzType="primary" nzSize="small" class="!px-5" [routerLink]="['/','home']">Launch App</button>
                <div
                    *ngIf="isWalletConnected"
                    class="cursor-pointer text-base rounded-full px-3 flex justify-start items-center gap-x-2 h-10 bg-black"
                    nz-dropdown
                    [nzDropdownMenu]="profileMenu"
                    [nzTrigger]="'click'"
                >
                    <app-hexagon-img class="w-[28px]" [imgUrl]="walletStatusService.userProfileImg"></app-hexagon-img>
                    <span> {{ (commonService.getAddrName(userProfile) || walletStatusService.walletAddress)| omit:4:4 }} </span>
                </div>

                <nz-dropdown-menu #profileMenu="nzDropdownMenu">
                    <ul nz-menu class="!px-0 !py-0 !rounded-2xl w-[280px] overflow-hidden">
                        <app-profile-dropdown nz-menu-item></app-profile-dropdown>
                    </ul>
                </nz-dropdown-menu>
            </div>
        </div>
    </div>
</header>

<!-- Mobile -->
<header *ngIf="commonService.isMobile" class="flex-between-center relative container p-5">
    <div class="flex justify-start items-center gap-x-2">
        <div
            *ngIf="walletStatusService.isWalletConnected && walletStatusService.registrationCompleted"
            class="flex justify-start items-center gap-x-2.5"
            [ngClass]="{'text-w3w': walletStatusService.isWalletConnected, 'text-white': !walletStatusService.isWalletConnected}"
        >
            <span
                nz-icon
                class="text-3xl !text-white"
                nzType="menu"
                nzTheme="outline"
                (click)="mobileNavVisible = !mobileNavVisible;$event.stopPropagation()"
            ></span>
        </div>

        <img src="/assets/imgs/socialscan-square-logo.png" class="h-8" alt="SocialScan" [routerLink]=" ['/']" />
    </div>

    <div class="flex-1 flex justify-end items-center gap-x-2">
        <app-search-input
            *ngIf="walletStatusService.isWalletConnected && walletStatusService.registrationCompleted"
            mode="mobile"
            class="border border-line border-solid rounded-full"
        ></app-search-input>

        <div
            class="w-8 text-xl h-8 rounded-full flex justify-center items-center cursor-pointer bg-black hover:bg-opacity-50 relative"
            nz-dropdown
            [nzDropdownMenu]="notificationMenu"
            [nzTrigger]="'click'"
        >
            <span nz-icon nzType="antd:bell"></span>

            <div *ngIf="hasUnreadNotifications" class="absolute w-2 h-2 rounded-full bg-[#FA4E3E] right-2.5 top-2.5"></div>
        </div>

        <img
            *ngIf="!walletStatusService.isWalletConnected"
            src="/assets/imgs/icon/user.png"
            class="w-8 h-8 p-1 rounded-full border border-line border-solid"
            alt=""
            (click)="login()"
        />

        <div *ngIf="walletStatusService.isWalletConnected" class="w-8 h-8 rounded-full" (click)="showDropdown()">
            <nz-avatar [nzAlt]="userProfile?.display_name" [nzSrc]="walletStatusService.userProfileImg" nzIcon="user" [nzSize]="30" class="!bg-w3w"></nz-avatar>
        </div>
    </div>

    <!-- Mobile Navigation -->
    <div
        class="mobile-navigation-box z-[9999] shadow rounded-bl-md rounded-br-md"
        (click)="$event.stopPropagation()"
        [style.maxHeight]="mobileNavVisible ? '120px' : '0'"
    >
        <div class="mobile-navigation-links">
            <ul nz-menu nzMode="inline" [nzSelectable]="false">
                <li nz-menu-item (click)="login();mobileNavVisible = false">Home</li>
                <li nz-menu-item [routerLink]="['/','hemera','agents']" (click)="mobileNavVisible = false">Hemera AI Agents</li>
            </ul>
        </div>
    </div>
</header>

<nz-dropdown-menu #notificationMenu="nzDropdownMenu">
    <ul nz-menu class="!p-6 !rounded-3xl w-[80vw] md:w-[520px] overflow-hidden bg-[#0E0E0E] border border-white border-opacity-10">
        <div class="h-8 flex-between-center">
            <div class="text-base md:text-xl font-normal">Notifications</div>
            <button *ngIf="hasUnreadNotifications" nz-button nzType="text" nzSize="small" class="" (click)="markAllAsRead()">
                <span nz-icon nzType="antd:check-all" class="text-2xl"></span>
                <span>Mark all as read</span>
            </button>
        </div>

        <div *ngIf="notifications" class="max-h-[354px] overflow-y-auto mt-4 small-scrollbar">
            <ng-container *ngFor="let item of notifications;let last = last">
                <div
                    class="flex justify-start items-start gap-x-2 p-1 transition-all rounded-md hover:bg-white hover:bg-opacity-6 cursor-pointer"
                    (click)="markAsRead(item)"
                >
                    <nz-badge
                        style="margin-top: 8px"
                        [nzDot]="!item.is_read"
                        [nzStyle]="{
                            'backgroundColor':'#FA4E3E',
                            'width':'8px',
                            'height':'8px',
                        }"
                    >
                        <div
                            class="p-1.5 rounded-lg"
                            style="
                                background: linear-gradient(77.22deg, rgba(23, 182, 248, 0.1) 0%, rgba(23, 182, 248, 0.1) 0.01%, rgba(53, 208, 205, 0.1) 100%);
                            "
                        >
                            <img
                                *ngIf="item.type === 'token_subscription'"
                                [src]="item.logo || '/assets/imgs/icon/token-notification.png'"
                                alt=""
                                class="w-5 h-5"
                            />
                            <img
                                *ngIf="item.type === 'project_subscription'"
                                [src]="item.logo || '/assets/imgs/icon/project-notification.png'"
                                alt=""
                                class="w-5 h-5"
                            />
                            <img
                                *ngIf="item.type !== 'token_subscription' && item.type !== 'project_subscription'"
                                src="/assets/imgs/v3/system.png"
                                class="w-5 h-5"
                            />
                        </div>
                    </nz-badge>

                    <div class="flex-1 min-w-0 whitespace-break-spaces">
                        <div class="font-normal text-sm">{{item.title}}</div>

                        <div class="mt-1 text-sub-text text-xs">{{item.created_at | date:'HH:mm MMM dd, yyyy'}}</div>
                    </div>
                </div>

                <div *ngIf="!last" class="w-full gradient-line h-[1px] my-3"></div>
            </ng-container>

            <div *ngIf="notifications.length === 0" class="flex-center-center min-h-[200px] flex-col">
                <img src="/assets/imgs/no-data/no-notification.png" class="w-12" />
                <div class="mt-2 text-sub-text text-sm text-center">No notification</div>
            </div>
        </div>

        <nz-skeleton *ngIf="!notifications" [nzActive]="true"></nz-skeleton>
    </ul>
</nz-dropdown-menu>
